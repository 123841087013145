import React from "react";
import { H1, P } from "../Sections/SectionStyled";
import { SubPageWrapper, H2, Underline } from "./SubPageStyled";

const Impressum: React.FC = () => (
  <SubPageWrapper>
    <H1>Impressum</H1>
    <H2>Kontakt</H2>
    <P>
      Dirk Jacobs <br />
      Heilpraktiker Psychotherapie
      <br />
      Hypnotherapeut, Personal Coach
      <br />
      Fehrbelliner Straße 16
      <br />
      10119 Berlin
    </P>
    <P>
      Email: praxis@aufdichhoeren.de <br />
      Telefon: +49 178 3143168 <br />
      Website: www.aufdichhoeren.de
    </P>
    <H2>Berufsbezeichnung</H2>
    <P>
      Heilpraktiker für Psychotherapie <br />
      Erlaubnis durch Bezirksamt (Gesundheitsamt) Lichtenberg von Berlin am 14.
      01. 2021 <br />
      <Underline>Berufsordnung: </Underline>
      <br />
      <a href="http://www.bdh-online.de/178.0.html">
        http://www.bdh-online.de/178.0.html
      </a>
    </P>
    <H2>Praxis</H2>
    <P>
      Praxis für Psychotherapie nach dem Heilpraktikergesetz (HPrG) Erlaubnis
      <br />
      durch Bezirksamt (Gesundheitsamt) Lichtenberg von Berlin am 22.01.2021
    </P>
    <P>
      <Underline>Zuständige Behörde:</Underline> <br />
      Landesamt für Gesundheit und Soziales Berlin, <br />
      Fehrbelliner Platz 1 <br />
      10707 Berlin <br />
      Anmeldung seit 17.02.2022
    </P>
    <br />
    <br />
    <P>
      Images by Emely Timm, „Die Hoffotografen“ <br />
      Design by Thomas Senft <br />
      Realisierung by John Morrison
    </P>
  </SubPageWrapper>
);

export default Impressum;
